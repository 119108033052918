import DashboardIcon from "../../assets/dashboard-icon.svg";

const PLAN_TYPES = {
  TRIAL: "TRIAL",
  PAID: "PAID",
};

const UNSTRACT_SUBSCRIPTION_PLANS = {
  TRIAL: "TRIAL",
  STARTER: "STARTER",
  GROWTH: "GROWTH",
  ADVANCED: "ADVANCED",
  PRO: "PRO",
};

const dashboardSideMenuItem = (orgName) => {
  return {
    id: 1.1,
    title: "Dashboard",
    description: "Usage and Subscription details.",
    image: DashboardIcon,
    path: `/${orgName}/dashboard`,
    active:
      window.location.pathname.startsWith(`/${orgName}/dashboard`) ||
      window.location.pathname.startsWith(`/${orgName}/pricing`),
  };
};

const cloudHomePagePath = "dashboard";

export {
  PLAN_TYPES,
  UNSTRACT_SUBSCRIPTION_PLANS,
  dashboardSideMenuItem,
  cloudHomePagePath,
};
